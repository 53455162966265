import Page from '../../classes/Page';
import { aboutParallax } from './aboutParallax';
import { scrollToSection } from './scrollToSection';

const homePage = new Page({
  name: 'home',
  rootElementId: 'js-page-home',
  onCreate() {},
  onInit() {
    this.addComponent(aboutParallax)
    this.addComponent(scrollToSection)
  },
  onDestroy() {}
});

export default homePage;
