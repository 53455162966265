import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';
import { $style } from '../helpers/style';

const {get} = $dom

const modalId = 'js-add-application-modal';

export function modalOpening() {

  console.log(window.innerWidth);
  console.log(document.body.clientWidth);

  console.log(variables.dom.header);

  const handleModalOpen = () => {
    console.log('modal was opened');

    // $style.set(variables.dom.header, 'padding-right', window.innerWidth - document.body.clientWidth + 'px')
  }

  const handleModalClose = () => {
    console.log('modal was closed');

    // $style.remove(variables.dom.header, 'padding-right')
  }

  return new Component({
    name: 'modalOpening',
    requiredTargets: `#${modalId}`,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('show.bs.modal', `#${modalId}`, handleModalOpen)
        .on('hidden.bs.modal', `#${modalId}`, handleModalClose)
     },
    onDestroy() { }
  })
}