import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';
import FormValidator from '@yaireo/validator'
import { isElement } from '../helpers/_utilities';
import is from 'is_js';

const {
  callAll,
  attr,
  get,
  getAll,
  remove,
  addClass,
  hasClass,
  removeClass,
  exist,
  each
} = $dom;

const { formValidationMessages } = variables;
const
  formSelector = '.js-form-validate',
  formGroupSelector = '.js-group-validate',
  formFieldSelector = '.js-field-validate',
  formButtonSelector = '.js-field-button',
  notifyClassName = 'form__group-notify',
  needValidateClassName = 'is-need-validate'
  ;

export function formValidation() {

  const modalAddApplicationId = 'js-add-application-modal';

  const modal = get(`#${modalAddApplicationId}`);

  return new Component({
    name: 'formValidation',
    requiredTargets: formSelector,
    onCreate() {
      this.validator = null;
    },
    onInit() {
      this.validator = new FormValidator({
        classes: {
          item: formGroupSelector.replace('.', ''),
          bad: variables.classNames.invalid,
          alert: notifyClassName
        },
        texts: formValidationMessages
      });

      attr(formSelector, 'novalidate', '');

      this.checkField = formGroup => {
        if (formGroup instanceof Event) {
          const { target } = formGroup;

          if (isElement(target)) formGroup = target.closest(formGroupSelector)
        }

        const field = get(formFieldSelector, formGroup);

        if (isElement(field)) {
          const { valid, error } = this.validator.checkField(field);

          removeClass(formGroup, variables.classNames.dirty);
          removeClass(formGroup, variables.classNames.valid);

          is.not.empty(field.value) && addClass(formGroup, variables.classNames.dirty);

          valid && addClass(formGroup, variables.classNames.valid);
        }
      };

      this.buttonDisabler = event => {
        const form = event.target.closest(formSelector);

        const name = get('.js-form-name', form);
        const phone = get('.js-form-phone', form);

        if (Number(name.value) <= 0 || Number(phone.value) <= 0) {
          addClass(formButtonSelector, 'disabled');
          return
        }

        if (!hasClass(name.parentElement, variables.classNames.valid)) {
          return
        } else {
          addClass(formButtonSelector, 'disabled');
        }
        
        if (!hasClass(phone.parentElement, variables.classNames.valid)) {
          return
        } else {
          addClass(formButtonSelector, 'disabled');
        }

        removeClass(formButtonSelector, 'disabled');
      };

      this.handleForm = event => {
        const form = event.target.closest(formSelector);

        event.preventDefault();

        callAll(formGroupSelector, this.checkField, form);
        if (!this.validator.checkAll(form).valid) {
          event.preventDefault();

          addClass(formButtonSelector, 'disabled');
          console.log('form is invalid');
        } else {

          addClass(modal, variables.classNames.valid)
          console.log('form is valid');
        }
      };

      this.cleanfields = () => {
        const fields = getAll(formGroupSelector);

        each(fields, element => {
          get(formFieldSelector, element).value = '';

          removeClass(element, variables.classNames.valid)
          removeClass(element, variables.classNames.invalid)
          removeClass(element, variables.classNames.dirty)

          addClass(formButtonSelector, 'disabled');

          if (isElement(get(`.${notifyClassName}`, element))) {
            remove(get(`.${notifyClassName}`, element))
          }
        })
      }

      callAll(formGroupSelector, el => addClass(el, needValidateClassName));

      $events
        .add('blur', formFieldSelector, this.checkField)
        .delegate
        .on('submit', formSelector, this.handleForm)
        .on('input change', formGroupSelector, this.checkField)
        .on('input change', formGroupSelector, this.buttonDisabler)
        .on('hidden.bs.modal', window, this.cleanfields)
        ;
    },
    onDestroy() {
      this.validator = null;

      callAll(formSelector, form => {
        form.removeAttribute('novalidate');
        if (exist(formGroupSelector, form)) callAll(formGroupSelector, el => removeClass(el, needValidateClassName), form);
        if (exist('.' + notifyClassName, form)) callAll('.' + notifyClassName, el => remove(el), form);
      });


      $events
        .remove('blur', formFieldSelector, this.checkField)
        .delegate
        .off('submit', formSelector, this.handleForm)
        .off('input change', formGroupSelector, this.checkField)
        ;
    },
  })
}
