import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
// import Component from '../classes/Component';

const { get } = $dom

gsap.registerPlugin(ScrollTrigger)

const root = '.js-about';
// const aboutBoxSelector = '.js-about-box';
// const aboutSVGSelector = '.js-about-svg';
const aboutImageSelector = '.js-about-svg-img';

export function aboutParallax() {
  return new Component({
    name: 'aboutParallax',
    requiredTargets: root,
    onCreate() {
      this.parallax = null;
    },
    onInit() {
      this.parallax = gsap.timeline({
        scrollTrigger: {
          trigger: root,
          // start: 'center center',
          // end: '50%',
          scrub: true,
          markers: false
        }
      })
        .set(aboutImageSelector, {
          yPercent: 30,
        })
        .to(aboutImageSelector, {
          yPercent: -40,
        })
    },
    onDestroy() {
      this.parallax = null;
    }
  })
}