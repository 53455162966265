import Component from '../classes/Component';
import Headroom from 'headroom.js';
import { $dom } from '../helpers/dom';
import { generateId } from '../helpers/_utilities';
import variables from '../variables';
import { $style } from '../helpers/style';
import { $events } from '../helpers/events';

const stickyHeaderId = 'js-header';
const { createElement, insertBefore, addClass, removeClass, remove } = $dom;
const { header } = variables.dom;

export default function stickyHeader() {

  const spacerId = generateId();
  let headroom = null;

  const
    initHeadroom = () => {
      const
        headerHeight = header.offsetHeight,
        spacerEl = createElement('div', { id: spacerId })
        ;

      insertBefore(spacerEl, header);
      $style.set(spacerEl, 'height', headerHeight + 'px');
      addClass(header, 'is-initialized');

      headroom = new Headroom(header, {
        classes: {

          pinned: "header--pinned",
          unpinned: "header--unpinned",
          top: "header--top",
          notTop: "header--not-top",
        }
      });

      headroom.init();

      return headroom;
    },
    destroyHeadroom = () => {
      spacerId && remove(`#${spacerId}`);

      removeClass(header, 'is-initialized');

      if (headroom instanceof Headroom) {
        headroom.destroy();
        headroom = null;
      }
    },
    headroomReInit = () => {
      destroyHeadroom();
      initHeadroom()
    }
    ;


  return new Component({
    name: 'stickyHeader',
    requiredSelector: `#${stickyHeaderId}`,
    rootElementId: `#${stickyHeaderId}`,
    onCreate() {
      this.headroom = null;
    },
    onInit() {
      this.headroom = initHeadroom();

      $events.delegate.on(variables.customEventNames.resize, window, headroomReInit)
    },
    onDestroy() {
      destroyHeadroom();
      this.headroom = null;
      $events.delegate.off(variables.customEventNames.resize, window, headroomReInit)
    },
  })
}