import { Offcanvas } from "bootstrap";
import Component from "../../classes/Component";
import { $dom } from "../../helpers/dom";
import { $events } from "../../helpers/events";

const { get, attr } = $dom

const headerLinkSelector = '.js-header-nav-link';
const offcanvasLinkSelector = '.js-offcanvas-menu-link';

export function scrollToSection() {

  const menu = new Offcanvas('#js-offcanvas-menu');

  const scrollToElement = event => {
    event.preventDefault();

    const link = event.target.closest(headerLinkSelector);

    const sectionId = attr(link, 'href');

    get(sectionId).scrollIntoView();
  }

  const offcanvasLinkClickHandler = event => {
    event.preventDefault();

    const link = event.target.closest(offcanvasLinkSelector);

    const sectionId = attr(link, 'href');

    menu.hide();

    setTimeout(() => {
      get(sectionId).scrollIntoView();
    }, 200)
  }

  return new Component({
    name: 'scrollToSection',
    requiredTargets: headerLinkSelector,
    onCreate() { },
    onInit() {

      $events.delegate
        .on('click', headerLinkSelector, scrollToElement)
        .on('click', offcanvasLinkSelector, offcanvasLinkClickHandler)
        .on('show.bs.modal', window, () => menu.hide())
    },
    onDestroy() {
      $events.delegate
        .off('click', headerLinkSelector, scrollToElement)
        .off('click', offcanvasLinkSelector, offcanvasLinkClickHandler)
        .off('show.bs.modal', window, () => menu.hide())
     }
  })
}